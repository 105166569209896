import React from "react";
import { AccruentSlider } from "src/components/commonComponents";
import CaseStudiesCarouselItem from "./CaseStudiesCarouselItem";

const CaseStudiesCarousel = (props) => {
    const { caseStudyEntities, locale } = props

    const mappedEntities = caseStudyEntities.filter(e => e !== undefined)

    return (
        <div
            className="case-studies-carousel product-page-slick-wrapper tw-mt-8 md:tw-mt-0 tw-mb-0 tw-mx-auto tw-w-full md:tw-w-4/5"
            component="CaseStudiesCarousel"
        >
            <AccruentSlider sliderId="case-studies-carousel">
                {mappedEntities.map((entity, index) => {
                    return entity ? <CaseStudiesCarouselItem entity={entity} key={index} /> : false
                })}
            </AccruentSlider>
        </div>
    );
}

export default CaseStudiesCarousel