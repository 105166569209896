import React from "react";
import { AccruentImg, LocalizedLink } from "src/components/commonComponents";
import trimText from "../../../helpers/trimText";
import { StaticImage } from "gatsby-plugin-image";
import findSiteType from "../helpers/findSiteType";
const getImageObject = require("src/../helpers/getImageObject");
const defaultImage = "../../../static/placeholders/accruent_default-hero-placeholder.jpg";
const prefixLangcode = require('src/../helpers/prefixLangcode');

const CaseStudiesCarouselItem = (props) => {
  const { entity } = props;
  const isMc = findSiteType('mc');
  const isEms = findSiteType('ems');
  const isBrief = entity?.fieldTestimonialType === "tesimonial_type_brief"
  const cs_image_mobile = entity?.relationships?.fieldTeaserImage || entity?.relationships?.fieldMediaImage ? (
    <AccruentImg
      file={entity?.relationships?.fieldTeaserImage ? getImageObject(entity?.relationships?.fieldTeaserImage, entity?.fieldTeaserImage) : getImageObject(entity?.relationships?.fieldMediaImage, {}, "fieldMediaImage")}
      className="is-hidden-tablet tw-rounded-[48px_48px_0_0] md:tw-rounded-[48px_0_0_0] tw-h-full tw-relative tw-overflow-hidden tw-inline-block tw-align-top"
      width={550}
      height={522}
    />
  ) : (
    <StaticImage
      src={defaultImage}
      alt={'Default Image'}
      aspectRatio={2.0}
      placeholder="blurred"
      layout="constrained"
      className={'tw-w-full'}
    />
  );

  const cs_image = entity?.relationships?.fieldTeaserImage || entity?.relationships?.fieldMediaImage ? (
    <AccruentImg
      file={entity?.relationships?.fieldTeaserImage ? getImageObject(entity?.relationships?.fieldTeaserImage, entity?.fieldTeaserImage) : getImageObject(entity?.relationships?.fieldMediaImage, {}, "fieldMediaImage")}
      className="is-hidden-mobile tw-teaser-image-wrapper-child"
      layout="constrained"
    />
  ) : (
    <StaticImage
      src={defaultImage}
      alt={`Default Image`}
      aspectRatio={2.0}
      placeholder="blurred"
      className={'tw-w-full'}
    />

  );
  const csLogoAttrs = entity?.relationships?.fieldTeaserLogo?.gatsbyImage?.images?.fallback
  const cs_logo = entity?.relationships?.fieldTeaserLogo ? (
    <img
      {...csLogoAttrs}
      alt={entity?.fieldTeaserLogo?.alt}
      loading="eager"
      fetchpriority="high"
      className={'teaser-image tw-w-full'}
      decoding = "async"
    />
    // <AccruentImg file={getImageObject(entity?.relationships?.fieldTeaserLogo, entity.fieldTeaserLogo)} />
  ) : null;

  return (
    <div className="tw-m-0 md:tw-flex tw-justify-center">
      <div className="product-slick-slider-box tw-m-0 tw-p-0 tw-w-full md:tw-w-[85%] tw-block tw-flex-1">
        <div className="tw-teaser-carousel-item main">
          <div className="tw-block tw-flex-1 tw-m-0 tw-p-0">
            <div className="image tw-teaser-carousel-image-wrapper">
              {cs_image}
              {cs_image_mobile}
            </div>
          </div>

          <div className="column is-12-mobile right-side tw-column tw-m-0 !tw-p-0 tw-rounded-tr-none md:tw-rounded-tr-[48px] tw-border-l-[#eeeded] tw-border-l tw-border-solid md:tw-border-0 md:tw-border-l-0">
            <div className="tw-h-full tw-p-[20px_30px] md:tw-p-[32px_42px_42px_20px] tw-flex tw-flex-col">
              {!isBrief ? <div className={`tw-moff-quotes ${isEms ? 'tw-moff-ems' : ''} ${isMc ? 'tw-moff-mc' : ''}`}></div>
                :
                <div className="slick-company-logo-name brief tw-items-center tw-flex tw-justify-start tw-mb-2 md:tw-mb-0">
                  <div className="tw-mb-0 tw-mr-[15px]">
                    <figure
                      className="image homepage-slider-logo tw-block tw-relative tw-bg-transparent tw-w-[125px]"
                      style={{ maxWidth: "125px" }}
                    >
                      {cs_logo ? cs_logo : null}
                    </figure>
                  </div>
                </div>

              }
              <div className="feedback-desc tw-mb-8">
                <div className={`tw-product-content-box product-content-box ${isBrief ? "brief-content" : ""}`}>
                  {!isBrief ?
                    entity?.fieldCustomerFeedback && <p className="tw-text-[#222223] tw-font-Roboto tw-text-[22px] tw-font-black tw-tracking-[0] tw-leading-[29px] !tw-mb-0">{trimText(entity?.fieldCustomerFeedback, 220)}</p>
                    :
                    entity?.fieldBrief && <p className="tw-text-accruent-heading-color tw-font-Roboto tw-text-[22px] tw-font-semibold tw-tracking-[0] tw-leading-[29px] !tw-mb-0 tw-mt-6" >{trimText(entity?.fieldBrief, 300)}</p>
                  }
                </div>
                {!isBrief &&
                  <div className="slick-company-logo-name tw-items-center tw-flex tw-justify-start">
                    <div className="tw-block  tw-mr-[15px] tw-mb-0">
                      <figure
                        className="image homepage-slider-logo tw-w-[125px] tw-bg-transparent tw-block tw-relative"
                        style={{ maxWidth: "125px" }}
                      >
                        {cs_logo ? cs_logo : null}
                      </figure>
                    </div>
                    <div className="slick-company-name tw-pl-4 md:tw-pl-0">
                      <p className="tw-text-[#222223] tw-font-Roboto tw-text-[16px] md:tw-text-[16px] tw-font-bold tw-tracking-[0] tw-leading-[21px] !tw-mb-0">{entity?.fieldCustomerName}</p>
                      <p className="tw-text-accruent-heading-color tw-font-Roboto tw-text-[16px] tw-tracking-[0] tw-leading-[21px] !tw-mb-0">{entity?.fieldCustomerDesignation}</p>
                    </div>
                  </div>}
              </div>
              <div className="tw-block">
                <LocalizedLink
                  to={prefixLangcode(entity?.entityUrl?.path, entity?.locale)}
                  className="tw-bg-transparent tw-text-accruent_sapphire tw-text-[16px] tw-font-bold tw-leading-[16px] tw-tracking-[1.46px] tw-p-[14px_24px] tw-rounded-[12px] tw-border-[2px] tw-border-solid tw-border-accruent_sapphire tw-font-Poppins tw-whitespace-break-spaces tw-h-auto hover:tw-text-white hover:tw-bg-accruent_sapphire hover:tw-border-accruent_sapphire tw-inline-flex tw-items-center tw-relative"
                >
                  Read Story
                </LocalizedLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudiesCarouselItem;
