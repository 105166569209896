import React from "react";
import CaseStudiesCarousel from "./CaseStudiesCarousel";
import getTitleId from "../helpers/getTitleId";

const CaseStudiesBlock = ({ contentCaseStudiesSection, locale = "en" }) => {
    const { caseStudiesSectionDesc, caseStudiesSectionTitle, hideCaseStudiesSectionTitle } = contentCaseStudiesSection
    const fieldCaseStudyItem =  contentCaseStudiesSection?.relationships?.fieldCaseStudyItem
    return (
        <div
            className="case-studies-section"
            component="CaseStudiesBlock"
        >
            <div className="header center-header" id={getTitleId(caseStudiesSectionTitle)}>
                {!hideCaseStudiesSectionTitle && (
                    <h2 className="title title-3 has-text-centered">{caseStudiesSectionTitle}</h2>
                )}
                {!!caseStudiesSectionDesc?.value && (
                    <div className="mb-5">
                        <div dangerouslySetInnerHTML={{ __html: caseStudiesSectionDesc.value }} className="dangerously-html-handle" />
                    </div>
                )}
            </div>
            {fieldCaseStudyItem?.length > 0 && <CaseStudiesCarousel caseStudyEntities={fieldCaseStudyItem} locale={locale} />}
        </div>
    );
}

export default CaseStudiesBlock
